.interview-block {

    .tab {

        &__main-col,
        &__aside-col {
            position: relative;
            overflow: hidden; } }

    &__offer-block {
        @include centerV;
        right: 0;
        z-index: 20;

        &._sticky {
            position: absolute;
            right: auto;
            left: 0;
            top: 0;
            bottom: auto;
            transform: none;
 } } }            //height: 0

.interview-slider {
    width: 100%;
    flex-grow: 1;
    background-image: url("../images/bg-interview.png");
    background-repeat: no-repeat;
    background-size: 100% calc(100% + 100px);
    background-position: bottom center;

    .swiper-pagination {
        left: 0;
        right: 0;
        bottom: 2.5vh;
        width: 100%;
        font-style: italic;
        font-size: 4.63vh;
        line-height: 4.81vh;
        color: #515151;
        letter-spacing: -0.4vh; }

    .swiper-button-prev,
    .swiper-button-next {
        @include btn-reset;
        top: auto;
        bottom: 3vh;
        margin: 0;
        display: flex;
        width: 5vh;
        height: 5vh;
        background-image: none;
        z-index: 11;

        svg {
            display: block;
            margin: auto;
            transform: rotate(90deg);
            fill: #515151; } }

    .swiper-button-prev {
        left: 50%;
        margin-left: -11vh; }

    .swiper-button-next {
        right: 50%;
        margin-right: -11vh;

        svg {
            transform: rotate(-90deg); } } }

.interview-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8vh 4rem 19vh;
    height: calc(100vh - 99px);
    text-align: center;

    &._ig {
        background-image: url("../images/icon-ig.png");
        background-repeat: no-repeat;
        background-size: 46vh;
        background-position: center top 25.6vh; }

    &__content {
        max-width: 100%; }

    &__title {
        margin: 0 0 2.2vh;
        font-weight: 400;
        text-transform: uppercase;
        font-family: $font-alt;
        font-size: 5.555555vh;
        line-height: 6.482vh;
        letter-spacing: 0.3vh; }

    &__lead {
        margin: 0 auto 5.185vh;
        max-width: 66.5rem;
        font-weight: 900;
        font-size: 2.222222vh;
        line-height: 3.703vh; } }

.interview-img {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("../images/interview01.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }

.question-block {
    margin: 0 70px;
    padding: 5vh 0;
    max-width: 66.5rem;
    height: 30vh;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;

    a {
        text-decoration: underline; }

    .mCustomScrollBox {
        padding-right: 5px; }

    .mCSB_scrollTools {
        background: #fff;
        opacity: 1;
        //opacity: 0 !important

        .mCSB_dragger_bar {
            background: #000 !important; } }

    &__question {
        margin: 0 0 4.166vh;
        font-weight: 500;
        font-size: 2.037vh;
        line-height: 3.148vh; }

    &__answer {
        margin: 0;
        font-size: 16px;
        line-height: 30px;

        @media (max-width: $screenXXL - 1) {
            font-size: 2vh;
            line-height: 3vh; }

        a {
            @include hover;
            text-transform: uppercase;
            font-weight: 900;
            color: $textColor; } }

    &__link {
        @include hover;
        color: $textColor;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;

        @media (max-width: $screenXXL - 1) {
            font-size: 2vh;
            line-height: 3vh; } } }

._mobile {

    .interview-img {
        position: relative;
        height: 62.5vh;
        background-size: cover;
        background-position: top center; }

    .interview-slide {
        height: auto;
        padding: 4rem 5rem;

        &__title {
            font-size: 4.6rem;
            line-height: 5rem; }

        &__lead {
            margin-bottom: 3.4rem;
            padding: 0 1rem;
            font-size: 2.4rem;
            line-height: 4rem; } }

    .question-block {
        margin: 0;
        padding: 3rem 0;
        max-width: 100%;
        height: auto;

        &__question {
            margin-bottom: 2.2rem;
            font-size: 2.2rem;
            line-height: 3.4rem; }

        &__answer {
            padding: 0 1rem;
            font-size: 1.6rem;
            line-height: 3rem; } } }
