// ----------------------------------------------------------------------
    // Sizes
// ----------------------------------------------------------------------
$screenXXL: 1920px;
$screenXL: 1400px;
$screenL: 1280px;
$screenM: 1024px;
$screenS: 900px;
$screenXS: 768px;
$screenXS-s: 600px;
$screenXXS: 640px;
$screenMob: 500px;


// ----------------------------------------------------------------------
    // Colors
// ----------------------------------------------------------------------
$transparent: rgba(255, 255, 255, 0);
$textColor: #333;
$pink: #fbc9dd;
$l-pink: #feebf1;
$fucsia: #cc0062;
$mint: #cafce0;
$peach: #fce9ca;
$yellow: #fffcca;
$gray: #515151;

// ----------------------------------------------------------------------
    // Gradients
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
    // Fonts
// ----------------------------------------------------------------------
$font: Roboto, Arial, sans-serif;
$font-alt: 'New Weleda', Arial, sans-serif;
