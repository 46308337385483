.appearing-text {

    .string {
        overflow: hidden;

        span {
            display: block;
            opacity: 0;
            transform: translateY(-110%);
            transition: transform 0.4s ease-in-out 1.6s, opacity 0.4s ease-in-out 1.6s;

            .interview-block & {
                transition: transform 0.4s ease-in-out 1s, opacity 0.4s ease-in-out 1s; }

            .active &,
            .animated & {
                opacity: 1;
                transform: translateY(0); }

            .swiper-slide & {
                opacity: 0;
                transform: translateY(-110%);
                transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out; }

            .swiper-slide.delay & {
                transition-delay: 1.2s; }

            .swiper-slide-animate & {
                opacity: 1;
                transform: translateY(0); } }

        &:nth-last-child(2) {

            span {
                transition-delay: 1.75s; }

            .swiper-slide & {
                span {
                    transition-delay: 0.15s; } }

            .swiper-slide.delay & {

                span {
                    transition-delay: 1.35s; } }

            .interview-block & {

                span {
                    transition-delay: 1.15s; } } }

        &:nth-last-child(3) {

            span {
                transition-delay: 1.9s; }

            .swiper-slide & {

                span {
                    transition-delay: 1.35s; } }

            .interview-block & {

                span {
                    transition-delay: 1.15s; } } } } }
