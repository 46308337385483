@mixin placeholder($content) {
    &::-webkit-input-placeholder {
        color: $content; }
    &:-moz-placeholder {
        color: $content; }
    &::-moz-placeholder {
        color: $content; }
    &:-ms-input-placeholder {
        color: $content; } }

@mixin icon($icon, $width, $height) {
    width: $width;
    height: $height;
    background: url('../img/svg/' + $icon + '.svg') 0 0 no-repeat;
    background-size: $width $height; }

@mixin selection {
    ::selection {
        @content; }
    ::-moz-selection {
        @content; } }

@mixin vAlignAbs() {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

@mixin centerH {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }

@mixin centerV {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

@mixin row-component {
    display: flex;
    align-items: center; }

@mixin btn-reset {
    padding: 0;
    background-color: $transparent;
    border: none;
    outline: none;

    &:focus {
        outline: none; } }

@mixin list-reset {
    margin: 0;
    padding: 0;
    list-style: none; }

@mixin field-reset {
    padding: 0;
    background-color: $transparent;
    border: none;
    border-radius: 0;

    &:focus {
        outline: none; } }

@mixin hover {
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 0.7; } }

@mixin numeric {
    font-variant-numeric: oldstyle-nums;
    -moz-font-feature-settings: 'onum';
    -webkit-font-feature-settings: 'onum';
    font-feature-settings: 'onum' on; }
