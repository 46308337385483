.text-page {
    //background-color: #fff
    text-align: center;
    color: $textColor;

    &__wrapper {
        margin: 0 auto;
        padding: 3rem 3rem 8.5rem;
        max-width: 600px; }

    h2 {
        margin: 0 0 22px;
        text-transform: uppercase;
        font-family: $font-alt;
        font-weight: 400;
        font-size: 60px;
        line-height: 70px; }

    .lead {
        margin: 0;
        padding-bottom: 55px;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #000;
        border-bottom: 1px solid #000; }

    &__section {
        padding-top: 54px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000;

        &:last-child {
            padding-bottom: 0; } }

    h3 {
        margin: 0 0 25px;
        font-weight: 500;
        font-size: 22px;
        line-height: 34px; }

    p {
        margin: 0 0 30px;
        font-size: 16px;
        line-height: 30px; }

    ul {
        @include list-reset;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 30px;
        max-width: 600px;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;

        &.principles {
            flex-direction: column;
            margin-top: 60px;
            width: 100%;
            max-width: 100%;
            font-weight: 400; } } }

.principle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    padding-top: 60px;
    padding-bottom: 30px;
    border-top: 1px solid #000;

    &__icon {
        margin-bottom: 35px;
        max-width: 120px;

        img {
            display: block;
            max-width: 100%;
            height: auto; } } }

._mobile {

    .text-page {

        &__section {
            padding-top: 5.4rem;

            &:last-child {
                padding-bottom: 0; } }

        &__wrapper {
            padding-top: 0; }

        h3 {
            margin-bottom: 3rem;
            font-size: 3rem;
            line-height: 4.6rem; }

        p {
            margin-bottom: 3rem;
            font-size: 2rem;
            line-height: 3rem; }

        ul {
            font-size: 2rem;
            line-height: 3rem; } }

    .principles {
        margin-top: 6rem; }

    .principle {
        padding-top: 6rem;
        padding-bottom: 3rem;

        &__icon {
            margin-bottom: 3.5rem; } } }
