.tab-controls {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100px;
    background-color: #fff;
    z-index: 1; }

.main-nav {
    @include list-reset;
    display: flex;
    justify-content: center;
    transform: rotate(90deg) translateX(50px) translateX(-50%) translateY(-100%);
    transform-origin: top left;
    width: 100vh;
    height: 100px;
    text-align: center;
    font-family: $font-alt;
    font-size: 1.8519vh;
    line-height: 100px;

    a {
        display: block;
        padding: 0 4.15vh;
        color: $textColor;
        white-space: nowrap; }

    &__item {
        transition: background-size 0.5s ease;
        background-image: url("../images/bg-nav-active.png");
        background-repeat: no-repeat;
        background-size: 0 100%;
        background-position: center;
        cursor: pointer;

        &.active,
        &:hover {
            background-size: 100% 100%; } } }

._mobile {

    .tab-controls {
        top: 98px;
        width: 60px;

        @media (max-width: $screenXXS - 1) {
            top: 49px;
            width: 40px; } }

    .main-nav {
        width: calc(100vh - 98px);
        height: 60px;
        font-size: 2.26vh;
        line-height: 60px;
        transform: rotate(90deg) translateX(30px) translateX(-50%) translateY(-100%);

        @media (max-width: $screenXXS - 1) {
            width: calc(100vh - 49px);
            height: 40px;
            line-height: 40px;
            transform: rotate(90deg) translateX(20px) translateX(-50%) translateY(-100%); } } }
