.tabs {
    display: flex;
    height: 100vh; }

.tabs-wrapper {
    position: relative;
    flex: none;
    margin-right: 100px;
    width: calc(100vw  - 100px);
    background-color: $pink; }

.tab {
    position: absolute;
    top: -20px;
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease, top 0.5s ease;

    &.scrollable {
        overflow-y: scroll; }

    &.active {
        position: relative;
        top: 0;
        opacity: 1;
        pointer-events: auto; }

    &__inner {
        height: 100vh;
 }        //overflow-y: scroll

    &__wrapper {
        display: flex;
        height: 100vh; }

    &__main-col {
        flex: none;
        display: flex;
        flex-direction: column;
        width: 57%;
        max-width: 57%; }

    &__aside-col {
        flex: none;
        width: 43%;
        max-width: 43%;

        .mCSB_draggerRail {
            background: #fff; }

        .mCSB_scrollTools {
            background: #fff;
            opacity: 1; }

        .mCSB_dragger_bar {
            background: #000 !important; }

        //.mCSB_scrollTools
 }        //  opacity: 0 !important

    &__body {
        flex-grow: 1; } }

.tab-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }

._mobile {

    .tabs {
        height: 100vh;
        overflow: hidden; }

    .tabs-wrapper {
        padding-top: 98px;
        width: calc(100% - 60px);

        @media (max-width: $screenXXS - 1) {
            padding-top: 49px;
            width: calc(100% - 40px); } }

    .tab {
        height: calc(100vh - 98px);

        &__wrapper {
            flex-direction: column;
            height: auto; }

        &__inner {
            height: auto; }

        &__main-col {
            width: 100%;
            max-width: 100%; }

        &__aside-col {
            width: 100%;
            max-width: 100%; } }

    .tab-image {
        height: 62.5rem; } }
