.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: -40px;
    display: flex;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease;
    overflow-y: scroll;

    &.opened {
        opacity: 1;
        pointer-events: auto;
        z-index: 100; } }

.modal {
    position: relative;
    left: -12px;
    margin: auto;
    background-color: $pink;

    &__close {
        @include btn-reset;
        position: absolute;
        top: 40px;
        right: 40px;
        width: 23px;
        height: 23px;
        z-index: 101;

        &:before,
        &:after {
            @include center;
            content: "";
            transform: translate(-50%, -50%) rotate(45deg);
            width: 32px;
            height: 1px;
            background-color: #000; }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } }

    &__headline {
        margin: 0;
        text-align: center;
        font-family: $font-alt;
        font-size: 4rem;
        line-height: 5rem;
        color: $textColor; } }

._mobile {

    .modal-overlay {
        right: 0;
        padding-top: 120px;

        @media (max-width: $screenXXS - 1) {
            padding: 10px;
            padding-top: 60px; } }

    .modal {
        left: 0;
        width: 100%;
        min-height: 100%;

        &__close {

            @media (max-width: $screenXXS - 1) {
                top: 20px;
                right: 20px;
                width: 24px;
                height: 24px;

                &::before,
                &::after {
                    width: 24px; } } } } }
