.success-popup {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 4rem;
    width: 60rem;
    min-height: 47.8rem;
    background-image: linear-gradient(to top , $mint 0%, $mint 7.9rem, $transparent 7.9rem, $transparent 100%), url("../images/bg-flowers.png");
    background-repeat: no-repeat;
    background-size: 100% auto, cover; }

.success-message {

    &__products {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 8rem;

        img {
            display: block;

            &:first-child {
                margin-right: 1rem;
                width: 6.8rem;
                height: 14.9rem; }

            &:nth-child(2) {
                margin-right: 0.2rem;
                width: 6.7rem;
                height: 16.5rem; }

            &:nth-child(3) {
                width: 11.7rem;
                height: 17.9rem; } } } }
