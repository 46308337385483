.page-header {
    position: relative;
    top: 0;
    left: 0;
    //right: 0
    box-sizing: border-box;
    padding: 17px 0;
    max-width: 100%;
    background-color: #fff;
    z-index: 1;

    &._main {
        position: fixed;
        width: 100%;
        max-width: 100%; }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 40px;
        width: 100%;
        max-width: 1560px;

        ._main & {
            padding: 0 10px;
            box-sizing: border-box;
            max-width: 1560px;
 } }            //justify-content: center

    &__inner {
        flex-grow: 1;
        display: flex;
        max-width: 1160px;

        @media (max-width: 1700px) {
            max-width: 850px; } }

    &__el-wrapper {
        flex: none;
        display: flex;
        width: 200px;

        @media (max-width: 1700px) {
            width: auto;

            &:first-child {

                ._main & {
                    margin-left: auto; } }

            &:last-child {

                ._main & {
                    margin-right: auto; } } }

        .logo-mc {

            ._main & {
                margin-left: auto;

                @media (max-width: 1700px) {
                    margin-left: 0; } } } }

    &__label {
        margin: 7px 0 0;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.7px;
        white-space: nowrap; }

    img {
        display: block;
        width: 100%;
        height: auto; } }

.logo {
    @include hover;
    display: block;
    margin: 18px auto 0;
    width: 120px; }

.logo-mc {
    @include hover;
    display: block;
    margin-top: 3px;
    width: 111px; }

._mobile {

    .page-header {
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;

        @media (max-width: $screenXXS - 1) {
            padding: 9px 0; }

        &__wrapper {
            padding: 0 20px;

            @media (max-width: $screenXXS - 1) {
                padding: 0 10px; } }

        &__el-wrapper {
            width: 130px;

            @media (max-width: $screenXXS - 1) {
                width: 65px; }

            &:last-child {
                justify-content: flex-end; } }

        &__label {
            white-space: normal;

            @media (max-width: $screenXXS - 1) {
                margin-top: 4px;
                font-size: 8px;
                line-height: 12px;
                letter-spacing: 0.35px; } } }

    .logo {

        @media (max-width: $screenXXS - 1) {
            margin-top: 9px;
            width: 60px; } }

    .logo-mc {

        @media (max-width: $screenXXS - 1) {
            margin-top: 2px;
            width: 55px; } } }
