.request-popup {
    display: flex;
    width: calc(100% - 50px);
    max-width: 136.6rem;

    &__info-col {
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 3rem;
        width: 44%;
        box-sizing: border-box;
        background-color: $yellow;
        background-image: url("../images/bg-ribbon.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: bottom 54px left; }

    &__form-col {
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 2.4rem;
        width: 56%;
        box-sizing: border-box;
        background-image: url("../images/bg-flowers.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center; }

    &__slogan {
        margin: 0 auto 2.7rem;
        max-width: 44rem;
        font-family: $font-alt;
        font-size: 4rem;
        line-height: 5rem;

        .rose {
            color: $fucsia; } }

    &__products {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
            display: block;

            &:first-child {
                margin-right: 2.9rem;
                width: 12.5rem;
                height: 27.3rem; }

            &:nth-child(2) {
                margin-right: 1.2rem;
                width: 12.3rem;
                height: 30rem; }

            &:nth-child(3) {
                width: 21.8rem;
                height: 32.8rem; } } }

    &__inner {
        margin: 0 auto;
        max-width: 56rem; } }

._mobile {

    .request-popup {
        flex-direction: column;

        &__info-col,
        &__form-col {
            width: 100%;
            flex-grow: 1; }

        &__info-col {
            background-image: url("../images/bg-ribbon-m.png");
            padding-bottom: 2rem; }

        &__slogan {
            text-align: center;
            font-size: 3.6rem;
            line-height: 4.4rem; }

        &__products {
            margin-top: auto;

            img {

                &:first-child {
                    width: 7.7rem;
                    height: 16.7rem; }

                &:nth-child(2) {
                    width: 7.6rem;
                    height: 18.6rem; }

                &:nth-child(3) {
                    width: 13.3rem;
                    height: 20.2rem; } } }

        &__inner {
            margin: 0 auto;
            max-width: 90%;

            @media (max-width: $screenXXS - 1) {
                max-width: 100%; } } } }
