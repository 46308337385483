.promo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    height: 100%;
    min-height: 100vh;
    text-align: center;
    color: $textColor;

    &__inner {
        padding: 5.5rem 9rem;

        @media (max-width: $screenXXL - 1) {
            padding: 4rem; } }

    &__title {
        margin: 0 0 2rem;
        text-transform: uppercase;
        font-weight: 400;
        font-family: $font-alt;
        font-size: 5rem;
        line-height: 6rem;

        @media (max-width: $screenXXL - 1) {
            font-size: 4rem;
            line-height: 5rem; } }

    &__lead {
        margin: 0 0 5rem;
        padding-bottom: 5rem;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 3rem;
        border-bottom: 1px solid #000;

        @media (max-width: $screenXXL - 1) {
            margin-bottom: 3rem;
            padding-bottom: 3rem;
            font-size: 1.4rem;
            line-height: 2.2rem; } }

    &__motto {
        margin: 0 0 2.5rem;
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 3.4rem;

        @media (max-width: $screenXXL - 1) {
            margin-bottom: 2rem;
            font-size: 1.6rem;
            line-height: 3rem; } }

    &__text {
        margin: 0;
        font-size: 1.6rem;
        line-height: 3rem;

        @media (max-width: $screenXXL - 1) {
            font-size: 1.4rem;
            line-height: 2.2rem; } }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: 6rem;
        padding-top: 6rem;
        min-height: 11.6rem;
        border-top: 1px solid #000;

        @media (max-width: $screenXXL - 1) {
            padding-top: 3rem;
            margin-top: 3rem;
            min-width: 8.6rem; } }

    &__scroll {
        @include hover;
        display: flex;
        width: 5.6rem;
        height: 5.6rem;
        border: 1px solid #000;
        border-radius: 50%;

        svg {
            display: block;
            margin: auto;
            width: 3.9rem;
            height: 2.1rem; } } }

._mobile {

    .promo {

        &__inner {
            padding-bottom: 0; }

        &__title {
            font-size: 4.6rem;
            line-height: 5rem; }

        &__lead {
            font-size: 2.4rem;
            line-height: 4rem; }

        &__motto {
            font-size: 3rem;
            line-height: 4.6rem; }

        &__text {
            font-size: 2rem;
            line-height: 3rem; } } }
