.winners-popup {
    width: 640px;
    padding: 45px 50px;
    box-sizing: border-box;
    background-color: #fce0e9;
    background-image: url("../images/pattern.png");
    background-repeat: no-repeat;
    background-size: cover;

    ._mobile & {
        width: 100%;
        min-height: 0; }

    &__link {
        border-bottom: 1px solid #cc0062;
        color: #cc0062;

        &-wrap {
            text-align: center; } }

    &__title {
        font-size: 50px;
        line-height: 54px;
        font-weight: 300;
        text-align: center;
        max-width: 294px;
        margin: 0 auto 10px;
        text-transform: uppercase;
        color: #303030;
        letter-spacing: 5px;

        ._mobile & {
            font-size: 35px;
            line-height: 35px; } }

    &__text {
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        color: #303030;
        margin: 0;

        &._upper {
            text-transform: uppercase;
            margin-top: 15px; }

        ._mobile & {
            font-size: 11px;
            line-height: 16px; } }

    &__list {
        margin: 23px auto 30px;
        width: 165px;
        counter-reset: counter;

        &-item {
            padding-left: 20px;
            margin-bottom: 20px;
            position: relative;
            list-style-type: none;
            color: #303030;
            font-weight: 600;

            ._mobile & {
                font-size: 14px; }

            &:before {
                counter-increment: counter;
                content: " "counter(counter)".";
                position: absolute;
                left: -30px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                z-index: 1;
                padding-left: 10px;
                box-sizing: border-box;
                font-weight: 600;

                ._mobile & {
                    width: 28px;
                    height: 28px; } }

            &:nth-child(1):before {
                background-color: #ff92c2; }

            &:nth-child(2):before {
                background-color: #b3fce0; }

            &:nth-child(3):before {
                background-color: #ffe9ca; } } } }
