.product-slider {

    &__offer-block {

        &._sticky {
            position: absolute;
            right: auto;
            left: 0;
            top: 0;
            bottom: auto;
            transform: none;
            //height: 0
            z-index: 10; } }

    .swiper-button-prev,
    .swiper-button-next {
        @include btn-reset;
        margin-top: -29px;
        width: 56px;
        height: 56px;
        display: flex;
        background-image: none;
        border: 1px solid #000;
        border-radius: 50%;

        svg {
            display: block;
            margin: auto;
            transform: rotate(-90deg); } }

    .swiper-button-prev {
        left: calc(57% + 6.5rem);

        svg {
            transform: rotate(90deg); } }

    .swiper-button-next {
        right: 6.5rem; }

    .swiper-pagination {
        left: auto;
        right: 0;
        bottom: 3rem;
        width: 43%;
        font-style: italic;
        font-size: 4.63vh;
        line-height: 4.81vh;
        color: #515151;
        letter-spacing: -0.4vh; } }

.mCSB_draggerRail {
    background: #fff !important; }

.product-slide {

    .mCSB_scrollTools {
        opacity: 0 !important; }

    .tab {

        &__main-col,
        &__aside-col {
            overflow: hidden; }

        &__main-col {
            background-color: #fff;
            background-image: url("../images/bg-product.png");
            background-repeat: no-repeat;
            background-size: 104% calc(100% + 100px);
            background-position: bottom left; }

        &__aside-col {
            background-color: $pink;
            background-image: url("../images/bg-slider.png");
            background-repeat: no-repeat;
            background-size: 100% 100%; } }

    &__info {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        box-sizing: border-box;
        padding: 1.5vh 3rem;
        padding-top: 0;
        text-align: center;
        opacity: 0;
        transform: scale(1.2);
        transition: opacity 0.8s ease 0.1s, transform 0.8s ease;

        .swiper-slide-active & {
            opacity: 1;
            transform: scale(1); }

        &-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 2vh 70px 12vh;
            max-width: 100%; } }

    &__content {
        transform: translateX(100%);
        box-sizing: border-box;
        padding: 3vh 5.8rem 8vh;
        height: 100%;
        min-height: 100vh;
        transition: transform 0.7s ease;

        .swiper-slide-active & {
            transform: translateX(0); } }

    &__offer-block {
        @include centerV;
        right: 0; }

    &__product {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: calc(69.722222vh - 3rem);
        border-bottom: 1px solid #000; }

    &__tooltip-trigger {
        @include btn-reset;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        text-align: center;
        font-weight: 500;
        font-size: 29px;
        line-height: 35px;
        color: #fff;
        background-color: #000;
        border-radius: 50%;

        svg {
            display: block;
            fill: #fff; }

        &.ingredients-tooltip-trigger {
            top: 8.5vh;
            left: 18%; }

        &.lifehack-tooltip-trigger {
            top: 20vh;
            left: 10%;
            display: none; } }

    &__product-title {
        margin: 0 auto;
        max-width: 50%;
        min-height: 14.26vh;
        text-align: center;
        font-weight: 400;
        font-size: 2.407vh;
        line-height: 3.7vh;

        a {
            @include hover;
            //text-decoration: underline
            border-bottom: 1px solid $fucsia;
            color: $fucsia; } }

    &__product-img {
        a {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: -2vh;
            height: 49.54vh;

            &:hover img {
                transform: scale(1.1);
                transform-origin: center; } }

        img {
            flex: none;
            display: block;
            height: 100%;
            width: auto;
            transition: transform 0.3s ease; } }

    &__tooltip-container {
        height: 20vh; }

    &__tooltip {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding: 2vh 0;
        min-height: 17.59vh;
        font-size: 1.48vh;
        line-height: 2.777778vh;
        text-align: center;
        opacity: 0;
        //transition: opacity 0.5s ease
        border-bottom: 1px solid #000;

        &.show {
            position: relative;
            opacity: 1; }

        p {
            margin: 0;
            max-height: 16.666668vh;
            overflow: hidden;

            ._mobile & {
                max-height: none; } }

        b {
            margin-right: 8px; } }

    &__info-title {
        margin: 0 0 3vh;
        text-transform: uppercase;
        font-weight: 400;
        font-family: $font-alt;
        font-size: 5.555555vh;
        line-height: 7.073vh;

        .rose {
            display: block;
            color: $fucsia; } }

    &__info-text {
        margin: 0;
        font-size: 2vh;
        line-height: 3vh; }

    &__info-link {
        @include hover;
        @include row-component;
        font-size: 16px;
        line-height: 30px;
        color: #9f9397; } }

._mobile {

    .product-slider {

        .swiper-button-prev,
        .swiper-button-next {
            top: 32rem;
            margin-top: 0;

            @media (max-width: $screenXXS - 1) {
                width: 30px;
                height: 30px;

                svg {
                    width: 18px;
                    height: 9px; } } }

        .swiper-button-prev {
            left: 2rem; }

        .swiper-button-next {
            right: 2rem; } }

    .product-slide {
        background-color: $pink;

        &__content {
            opacity: 0;
            transform: none; }

        &__product {
            height: auto; }

        &__product-img {
            a {
                margin-bottom: 0;
                height: 37.2rem; } }

        &__tooltip-trigger {

            @media (max-width: $screenXXS - 1) {
                width: 24px;
                height: 24px;
                font-size: 16px;
                line-height: 20px;

                svg {
                    width: 12px;
                    height: 12px; } }

            &.lifehack-tooltip-trigger {
                top: 20rem;
                left: auto;
                right: 5.6rem;
                display: flex;

                @media (max-width: $screenXXS - 1) {
                    top: 19rem;
                    right: 1.6rem; } }

            &.ingredients-tooltip-trigger {
                top: 6.5rem;
                left: auto;
                right: 10.3rem;

                @media (max-width: $screenXXS - 1) {
                    top: 5.5rem;
                    right: 8rem; } } }

        &__product-title {
            padding-bottom: 2.2rem;
            max-width: 34rem;
            min-height: 0;
            font-size: 2.6rem;
            line-height: 4rem; }

        &__tooltip-container {
            height: auto; }

        &__tooltip {
            padding: 2.5rem 0;
            min-height: auto;
            font-size: 2rem;
            line-height: 3rem; }

        &__info-text {
            padding: 2rem 0;
            text-align: center;
            font-size: 2rem;
            line-height: 3rem; }

        &.swiper-slide-active {

            .product-slide {

                &__content {
                    opacity: 1; } } } } }

