.intro {
    position: relative;
    display: flex;
    box-sizing: border-box;
    padding-top: 100px;
    max-width: 100vw;
    height: 100vh;
    background-color: $pink;
    background-image: url('../images/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &__bg {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 1;

        .bg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            &-element {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0; } }

        ._one {
            position: absolute;
            top: 28rem;
            left: 11vw; }

        ._two {
            position: absolute;
            bottom: 29.5rem;
            right: 6.77vw; } }

    &__slogan {
        margin: 0;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12rem;
        line-height: 10rem;
        letter-spacing: 1rem; } }

.intro-box {
    position: relative;
    top: -3.4vh;
    margin: auto;
    width: 116rem;
    max-width: 98%;
    height: 60vh;
    background-image: url('../images/bg-intro.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;

    &__offer-block {
        position: absolute;
        top: 100%;
        right: 0; }

    &__link-block {
        position: absolute;
        z-index: 10;
        max-width: 50%;

        &._green {
            top: -7rem;
            left: 0;
            width: 38%;
            min-width: 45rem;
            max-width: 50%;
            background-color: $mint;
            background-image: url("../images/bg-link-1.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top right; }

        &._peach {
            top: -7rem;
            right: 0;
            width: 43%;
            min-width: 50rem;
            max-width: 50%;
            background-color: $peach; }

        &._pink {
            bottom: -7rem;
            left: 0;
            display: flex;
            flex-direction: column-reverse;
            width: 38%;
            min-width: 45rem;
            max-width: 50%;
            background-image: url("../images/bg-link-3.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top right; }

        &:hover {

            .intro-box__tooltip {
                max-height: 1000px; }

            .intro-box__link {
                background-size: 0 0;

                .icon {
                    transform: rotate(90deg); } } } }

    &__link {
        display: flex;
        box-sizing: border-box;
        padding: 2.1rem 4rem 1.9rem;
        width: 100%;
        height: 7rem;
        font-family: $font-alt;
        font-size: 2rem;
        line-height: 3rem;
        color: $textColor;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        transition: background-size 0.5s ease;

        &:hover {

            .icon {
                opacity: 0.7; } }

        ._green & {
            background-image: url("../images/bg-green.png"); }

        ._peach & {
            background-image: url("../images/bg-peach.png"); }

        ._pink & {
            background-image: url("../images/bg-pink.png"); }

        .icon {
            margin-left: auto;
            display: block;
            width: 3rem;
            height: 3rem;
            transition: opacity 0.3s ease, transform 0.5s ease; } }

    &__tooltip {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.7s ease;

        p {
            margin: 0 0 24px;
            font-size: 20px;
            line-height: 30px; }

        img {
            display: block;

            &._fullwidth {
                width: 100%;
                height: auto; } } }

    &__tooltip-inner {
        padding: 2.5rem 5rem 4rem 4rem; }

    &__images {
        display: flex;
        justify-content: center;
        align-items: center; }

    ._product01 {
        position: relative;
        top: 4.8rem;
        left: 3rem;
        display: block;
        width: 17.3rem;
        height: 31.4rem; }

    ._product02 {
        position: relative;
        top: 4.7rem;
        display: block;
        width: 21.5rem;
        height: 37.7rem; } }

.intro-nav {
    position: absolute;
    top: 70px;
    bottom: 70px;
    left: 0;
    right: 0;
    @include list-reset;
    display: flex;
    flex-direction: column;
    z-index: 1;

    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;

    &.opened {
        max-height: calc(100% - 140px);

        @media (max-width: $screenXXS - 1) {
            top: 40px;
            bottom: 40px;
            max-height: calc(100% - 80px); } }

    &__item {
        flex: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 33.333333%;
        text-align: center;
        font-family: $font-alt;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: $screenXXS - 1) {
            font-size: 12px;
            line-height: 15px; }

        &._peach {
            background-color: $peach;
            background-image: url("../images/bg-peach-m.png"); }

        &._pink {
            background-color: $pink;
            background-image: url("../images/bg-pink-m.png"); }

        &._green {
            background-color: $mint;
            background-image: url("../images/bg-green-m.png"); }

        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            color: $textColor;
            opacity: 0;
            transition: opacity 0.5s ease;

            .opened & {
                opacity: 1; } } } }

._mobile {

    .intro {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;

        @media (max-width: $screenXXS - 1) {
            padding-top: 50px; }

        &__inner {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin: 25px 80px;
            height: calc(100% - 50px);
            width: calc(100% - 160px);

            @media (max-width: $screenXXS - 1) {
                margin: 15px 40px;
                height: calc(100% - 25px);
                //max-height: 400px
                width: calc(100% - 80px); } }

        &__body {
            position: relative;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 1.5rem;
            padding-right: 1rem;
            background-image: url("../images/bg-intro-m.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center; }

        &__nav {
            position: static; }

        &__nav-trigger {
            @include btn-reset;
            @include row-component;
            justify-content: center;
            box-sizing: border-box;
            width: 100%;
            height: 70px;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            background-color: $mint;

            @media (max-width: $screenXXS - 1) {
                height: 40px;
                font-size: 14px;
                line-height: 20px; }

            .icon {
                position: relative;
                display: block;
                margin-left: 20px;
                width: 20px;
                height: 10px;

                @media (max-width: $screenXXS - 1) {
                    margin-left: 10px;
                    width: 10px;
                    height: 5px; }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 3px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 12px;
                    height: 12px;
                    border-bottom: 2px solid $textColor;
                    border-right: 2px solid $textColor;
                    border-top: 2px solid $transparent;
                    border-left: 2px solid $transparent;
                    transform: rotate(45deg);

                    @media (max-width: $screenXXS - 1) {
                        bottom: 2px;
                        width: 6px;
                        height: 6px;
                        border-width: 1px; } } } }

        &__slogan {
            font-weight: 400;
            font-size: 10rem;

            &:first-child {
                margin-bottom: 8vh; }

            &:last-child {
                text-align: right; } } } }


