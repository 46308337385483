.buttons {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-top: 100px;
    min-height: 100vh;
    background-color: $pink; }

.button {
    margin: 40px; }
