.offer-block {
    position: relative;

    button {
        @include btn-reset; }

    &__trigger {
        display: flex;
        width: 140px;
        height: 70px;

        ._vertical & {
            flex-direction: column;
            height: 210px;
            width: 70px; }

        &._main {
            width: 210px; }

        .text {

            &:hover {

                span {
                    transform: rotate(180deg); } } }

        .icon {

            &:hover {

                svg {
                    transform: scale(1.1); } } }

        .text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 70px;
            font-weight: 500;
            font-size: 40px;
            line-height: 44px;
            color: $fucsia;
            background-color: $peach;

            span {
                transition: transform 0.5s ease; } }

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 70px;
            background-color: $fucsia; }

        svg {
            display: block;
            width: 38px;
            height: 37px;
            fill: #fff;
            transition: transform 0.4s ease; } }

    &__tooltip {
        position: absolute;
        right: calc(100% + 10px);
        bottom: -18px;
        box-sizing: border-box;
        padding: 34px 40px;
        width: 334px;
        background-color: $peach;
        border-radius: 10px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease;

        &:before {
            content: "";
            position: absolute;
            bottom: 38px;
            right: -6px;
            width: 16px;
            height: 16px;
            background-color: $peach;
            transform: rotate(45deg);

            ._vertical & {
                bottom: 115px; } }

        p {
            margin: 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px; }

        .rose {
            color: $fucsia; }
        a {
            @include hover;
            color: $fucsia; }

        &.show {
            opacity: 1;
            pointer-events: auto;
            z-index: 1; } } }

.contest-link {
    @include hover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    letter-spacing: 1px;
    background-color: #ff91bf;
    transition: opacity 0.3s ease, letter-spacing 0.3s ease, font-size 0.3s ease;

    &:hover,
    &:focus {
        font-size: 11px;
        letter-spacing: 0; } }

._mobile {

    .offer-block {

        &__trigger {
            width: 100%;

            @media (max-width: $screenXXS - 1) {
                height: 40px;

                svg {
                    width: 19px;
                    height: 19px; }

                .text {
                    font-size: 20px;
                    line-height: 22px; } }

            button {
                width: 50%;

                @media (max-width: $screenXXS - 1) {
                    height: 40px; } }

            &._main {

                button {
                    width: 33.333333%; } } }

        &__tooltip {
            right: 60px;
            bottom: 82px;
            width: 334px;

            @media (max-width: $screenXXS - 1) {
                right: 30px;
                bottom: 50px;
                padding: 17px 20px;
                width: 200px;

                p {
                    font-size: 14px;
                    line-height: 20px; } }

            &:before {
                right: 27px;
                bottom: -6px;

                @media (max-width: $screenXXS - 1) {
                    right: 14px;
                    bottom: -3px; } } } }

    ._vertical {

        .offer-block {

            .contest-link {
                font-size: 4px;
                width: 100%; }

            &__trigger {
                width: 60px;
                height: 120px;

                @media (max-width: $screenXXS - 1) {
                    width: 30px;
                    height: 90px; }

                button {
                    width: 60px;
                    height: 60px;

                    @media (max-width: $screenXXS - 1) {
                        width: 30px;
                        height: 30px; }

                    svg {
                        width: 30px;
                        height: 30px;

                        @media (max-width: $screenXXS - 1) {
                            width: 15px;
                            height: 15px; } } }

                .text {
                    font-size: 32px;

                    @media (max-width: $screenXXS - 1) {
                        font-size: 16px;
                        line-height: 32px; } } }

            &__tooltip {
                right: auto;
                left: 76px;
                bottom: auto;
                top: 40px;

                @media (max-width: $screenXXS - 1) {
                    left: 40px;
                    top: 20px; }

                &::before {
                    left: -6px;
                    right: auto;
                    bottom: auto;
                    top: 40px;

                    @media (max-width: $screenXXS - 1) {
                        left: -3px;
                        top: 20px; } } } } }

    .contest-link {
        width: 33.333333%;
        font-size: 18px;
        line-height: 18px;

        @media (max-width: 639px) {
            height: 40px;
            font-size: 9px;
            line-height: 9px; }

        &:hover,
        &:focus {
            font-size: 16px;

            @media (max-width: 639px) {
                font-size: 8px; } } } }

