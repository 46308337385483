.btn {
    @include btn-reset;
    @include hover;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    min-width: 215px;
    text-align: center;
    font-family: $font;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    background-color: $gray;
    border-radius: 20px;

    &--black {
        background-color: $textColor; } }

._mobile {

    .btn {
        padding: 9px 10px 11px;
        font-size: 20px;

        @media (max-width: $screenXXS - 1) {
            padding: 5px;
            font-size: 12px;
            line-height: 16px; } } }
