.form {

    &__row {
        display: flex;
        width: 100%;

        .form__field-wrapper {
            width: calc(50% - 2rem);

            &:nth-child(2) {
                margin-left: 4rem; } } }

    &__field-wrapper {
        position: relative;
        width: 100%;
        overflow: hidden; }

    &__field {
        @include field-reset;
        box-sizing: border-box;
        margin-bottom: 4rem;
        padding-left: 2rem;
        width: 100%;
        height: 36px;
        font-family: $font;
        font-size: 16px;
        line-height: 26px;
        color: $textColor;
        border-bottom: 1px solid #000;
        border-radius: 0;

        &:invalid {
            .has-error & {
                border-color: red; } }

        &:focus + label {
            transform: translateY(-100%); } }

    &__field-label {
        position: absolute;
        top: 0;
        left: 2rem;
        font-size: 16px;
        line-height: 36px;
        color: rgba(51, 51, 51, 0.5);
        transition: transform 0.5s ease;
        pointer-events: none;

        &.hide {
            opacity: 0; } }

    &__buttons {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 15.5rem; }

    &__submit {
        min-width: 260px; }

    &__checkbox {
        position: absolute;

        &:checked + label:after {
            opacity: 1; }

        &:invalid ~ label:before {
            .has-error & {
                border-color: red; } } }

    &__checkbox-label {
        position: relative;
        margin-bottom: 12px;
        padding-left: 40px;
        font-size: 16px;
        line-height: 20px;
        color: $textColor;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            border: 1px solid $textColor;
            border-radius: 3px; }

        &:after {
            content: "";
            position: absolute;
            top: -3px;
            left: 8px;
            width: 8px;
            height: 16px;
            border-bottom: 2px solid $textColor;
            border-right: 2px solid $textColor;
            transform: rotate(45deg);
            opacity: 0;
            transition: opacity 0.3s ease; }

        a {
            @include hover;
            text-decoration: underline;
            color: inherit; } } }

._mobile {

    .form {

        &__buttons {
            margin-bottom: 3.4rem; }

        &__field {
            font-size: 22px;

            @media (max-width: $screenXXS - 1) {
                font-size: 14px; } }

        &__field-label {
            font-size: 22px;

            @media (max-width: $screenXXS - 1) {
                font-size: 13px; } }

        &__checkbox-label {
            font-size: 20px;

            @media (max-width: $screenXXS - 1) {
                display: block;
                padding-left: 24px;
                font-size: 12px;
                line-height: 16px;

                &::before {
                    width: 14px;
                    height: 14px;
                    border-radius: 2px; }

                &::after {
                    top: -5px;
                    left: 6px;
                    width: 5px;
                    height: 14px;
                    border-width: 1px; } } }

        &__submit {

            @media (max-width: $screenXXS - 1) {
                min-width: 130px; } } } }

